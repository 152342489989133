import AuthLayout from "Layout/AuthLayout";
import Landing from "Views/Security/Landing";
import Login from "Views/Security/Login";
import SignUp from "Views/Security/SignUp";
import { Navigate } from "react-router-dom";

export const AuthRoutes = [{
    path: "/",
    element: <AuthLayout />,
    children: [
        {
            path: "/",
            element: <Landing />,
        },
        {
            path: "/SignUp",
            element: <SignUp />,
        },
        {
            path: "/Login",
            element: <Login />,
        },
        {
            path: "*",
            element: <Navigate to="/" replace />,
        },
    ],
}]