import { Box, Alert, Button, Paper } from "@mui/material";
import { Component } from "react";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    static getDerivedStateFromError(error) {
        console.log(error)
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", flexDirection: "column", backgroundColor: "lightgray" }}>
                    <Paper><Alert severity="error">{"Unknown Error"}</Alert></Paper>
                    <Box component="img" src="/img/logo.png" sx={{ my: 3, width: "150px" }} />
                    <Button onClick={() => { window.location = "/" }}>{"Back to Home page"}</Button>
                </Box>
            )
        }

        return this.props.children;
    }
}
export default ErrorBoundary;