export const components = ({ palette, spacing, variables, transitions, breakpoints }, drawerOpen) => ({
    MuiTextField: {
        defaultProps: {
            autoComplete: "off",
            fullWidth: true,
            margin: "normal",
            variant: "outlined",
            size: "small",
        },
        styleOverrides: {
            root: {
                ".Mui-error": {
                    marginInline: 0
                }
            }
        }
    },
    MuiButton: {
        defaultProps: {
            variant: "contained",
        },
        styleOverrides: {
            root: {
                textTransform: "capitalize",
            },
        },
    },
    MuiCssBaseline: {
        styleOverrides: {
            body: {
                /* width */
                '& *::-webkit-scrollbar': {
                    width: '5px',
                    backgroundColor: 'transparent'
                },
                /* Handle */
                '& *::-webkit-scrollbar-thumb': {
                    backgroundColor: palette.primary.main,
                    borderRadius: '10px'
                },
                /* Handle on hover */
                '& *::-webkit-scrollbar-thumb:hover': {
                    background: palette.primary.dark
                },
            },
            main: {
                height: "100vh",
                width: "100%",
                overflowY: "auto",
                boxSizing: "border-box",
                backgroundColor: "inherit",
                padding: spacing(3),
                paddingTop: `calc(${spacing(3)} + ${variables.appBarHeight})`,
                transition: transitions.create(["margin", "width"]),
                ...(drawerOpen && {
                    [breakpoints.up("md")]: {
                        width: `calc(100% - ${variables.drawerWidth}px)`,
                    },
                }),
                ...(!drawerOpen && {
                    [breakpoints.up("md")]: {
                        marginLeft: -(variables.drawerWidth),
                        width: "100%"
                    },
                }),
            },
            ".mainNav": {
                flexShrink: { md: 0 },
                [breakpoints.up("md")]: {
                    width: variables.drawerWidth,
                },
                [breakpoints.down("md")]: {
                    width: "auto",
                },
            },
        },
    },
    MuiSelect: {
        defaultProps: {
            variant: 'outlined',
            size: 'small'
        }
    },
    MuiFormControl: {
        defaultProps: {
            margin: "normal"
        },
    },
    MuiPickersDay: {
        styleOverrides: {
            root: {
                "&.Mui-selected": {
                    backgroundColor: palette.secondary.main,
                    "&:hover": {
                        backgroundColor: palette.secondary.light
                    },
                    "&:focus": {
                        backgroundColor: palette.secondary.main
                    }
                },
                ".MuiPickersDay-today": {
                    borderColor: palette.secondary.main,
                },
            }
        }
    },
    MuiAppBar: {
        defaultProps: {
            elevation: 1,
        },
        styleOverrides: {
            root: {
                backdropFilter: "blur(17px)",
                "&.MuiAppBar-positionFixed": {
                    color: palette.text.primary,
                }
            },
        },
    },
    MuiDrawer: {
        styleOverrides: {
            root: {
                "& .MuiDrawer-paper": {
                    marginTop: 0,
                    width: variables.drawerWidth + "px",
                    height: "100vh",
                    overflowY: "hidden",
                    backgroundColor: palette.primary.dark, // Set the background color to white
                    color: palette.text.primary,
                    border: "none",
                    boxSizing: "border-box",
                },
                // nav group and nav item in sidebar
                ".MuiListSubheader-root,.MuiListItemButton-root": {

                    color: palette.text.primary,
                    "&:hover": {
                        opacity: 1,
                    },
                },

                ".MuiListItemButton-root": {
                    "&,& .MuiListItemText-root .MuiTypography-root,& .MuiListItemIcon-root": {
                        color: 'white',
                    },
                    // selected nav item
                    "&.Mui-selected": {
                        backgroundColor: palette.primary.main,
                        "& .MuiListItemText-root .MuiTypography-root,": {
                            opacity: 1,
                            color: palette.primary.contrastText,
                        },
                        "& .MuiListItemIcon-root": {
                            opacity: 1,
                            color: palette.primary.contrastText,
                        },
                    },
                },
            },
        },
    },
})