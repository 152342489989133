import { alpha } from "@mui/material/styles";
import { red, common, blueGrey, grey} from "@mui/material/colors";

export const palette = (mode) => ({
    primary: blueGrey,
    secondary: red,
    mode,
    transparent: alpha("#fff", 0),
    common,
    overlay: {
        light: alpha("#fff", 0.2),
        dark: alpha("#000", 0.2)
    },
    background: {default: mode == 'light' ? grey[100] : grey[800]}
});