import React, { useState, forwardRef, useEffect } from 'react';
import { Box, IconButton, Toolbar, Typography, styled } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet, useLocation, Link } from "react-router-dom";
import MuiAppBar from '@mui/material/AppBar';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiDrawer from '@mui/material/Drawer';
import UserProfileMenu from 'Components/Menus/UserProfileMenu';

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: '0.20s',
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: '0.20s',
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const ImageContainer = styled(Box)(({ theme }) => ({
  height: theme.spacing(8),
  width: "100%",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    display: "none"
  },
  overflow: 'hidden'
}))

const LogoImage = styled('img')({
  width: '70%',
  height: 'auto',
  objectFit: 'contain'
});

const MainLayout = ({ views }) => {
  const location = useLocation();
  const [open, setOpen] = useState(window.innerWidth >= 1920 ? true : false);
  const [contentWidth, setContentWidth] = useState("100%");
  const [isLaptopScreen, setIsLaptopScreen] = useState(window.innerWidth >= 1920);
  const handleDrawerOpen = () => {
    setOpen(true);
    setContentWidth("calc(100% - 280px)"); // Adjust the width based on the sidebar's width
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setContentWidth("100%");
  };
  const handleResize = () => {
    setIsLaptopScreen(window.innerWidth >= 1920);
    if (!isLaptopScreen) {
      handleDrawerClose(); // Close the drawer when screen size is not a laptop
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isLaptopScreen]);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open} color='transparent'>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {views.find(item => item.strPath === location.pathname.replace(/\/\d+$/, ""))?.strScreenName || ""}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <UserProfileMenu />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ background: 'white', color: "red" }}>
          <ImageContainer>
            <LogoImage src="img/logo.png" />
          </ImageContainer>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {views.filter(v => v.showMenu === "true").map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block', borderBottom: '1px solid #e0e0e0' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                component={forwardRef((props, ref) => (
                  <Link {...props} to={item.strPath} ref={ref} />
                ))}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {/* { item.strIcon !== '' ? createElement(item.strIcon) : null} */}
                  {item?.Icon}
                </ListItemIcon>
                <ListItemText primary={item.strScreenName} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ width: contentWidth, transition: "width 0.3s" }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;