import { Button, Grid, Card, Box, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Box sx={{ textAlign: "center", p: 1 }}>
                        <Typography variant="h3">
                            {"Page Not Found"}
                        </Typography>
                    </Box>
                    <Box sx={{ textAlign: "center", p: 1 }}>
                        <Button onClick={() => { navigate("/") }} >{"Back To Home Page"}</Button>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                        <img src="/img/notfound.svg" alt="notfound" width="100%" />
                    </Box>

                </CardContent>
            </Card>
        </Grid>

    )
}

export default NotFound;