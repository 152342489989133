import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import RHFTextField from "../../../Components/RHFControls/RHFTextField";

export const LoginForm = ({ onSubmit }) => {
    const { control, handleSubmit } = useForm();

    return (
        <>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate >
                <RHFTextField name="strUserEmail" control={control} label={"Email"} required={true} />
                <RHFTextField name="strUserPassword" control={control} label={"Password"} required={true} type="password" />
                <Button type="submit" sx={{width:'100%'}}>
                    {"Sign In"}
                </Button>
            </Box>
        </>
    );
};