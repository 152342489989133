import Loadable from "Components/Loaders/Loadable";
import MainLayout from "Layout/MainLayout";
import NotFound from "Views/Common/NotFoundPage";
import { lazy } from "react";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
import GroupTwoToneIcon from "@mui/icons-material/GroupTwoTone";
import BookmarkAddTwoToneIcon from "@mui/icons-material/BookmarkAddTwoTone";
import BookmarkAddedTwoToneIcon from "@mui/icons-material/BookmarkAddedTwoTone";
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MessageTwoTone from '@mui/icons-material/MessageTwoTone';

const views = {
  Admin: [
    {
      strScreenName: "Releases",
      strPath: "/Releases",
      Icon: <CalendarMonthTwoToneIcon />,
      strParam: "",
      showMenu: "true",
    },
    {
      strScreenName: "Edit Release",
      strPath: `/Releases/Edit`,
      strParam: "/:id",
      Icon: <></>,
      showMenu: "false",
    },
    {
      strScreenName: "Add Release",
      strPath: "/Releases/Add",
      strParam: "",
      Icon: <></>,
      showMenu: "false",
    },
    {
      strScreenName: "Commodities",
      strPath: "/Commodities",
      Icon: <StorefrontTwoToneIcon />,
      strParam: "",
      showMenu: "true",
    },
    {
      strScreenName: "Account Settings",
      strPath: "/Profile",
      Icon: <></>,
      strParam: "",
      showMenu: "false",
    },
    {
      strScreenName: "Users",
      strPath: "/Users",
      Icon: <GroupTwoToneIcon />,
      strParam: "",
      showMenu: "true",
    },
    {
      strScreenName: "Add User",
      strPath: "/Users/Add",
      Icon: <></>,
      strParam: "",
      showMenu: "false",
    },
    {
      strScreenName: "Edit User",
      strPath: "/Users/Edit",
      Icon: <></>,
      strParam: "/:id",
      showMenu: "false",
    },
    {
      strScreenName: "Book Commodities",
      strPath: "/BookCommodities",
      Icon: <BookmarkAddTwoToneIcon />,
      strParam: "",
      showMenu: "true",
    },
    {
      strScreenName: "Allocated commodities",
      strPath: "/AllocatedCommodities",
      Icon: <BookmarkAddedTwoToneIcon />,
      strParam: "",
      showMenu: "true",
    },
    {
      strScreenName: "Reallocate commodities",
      strPath: "/ReallocateCommodities",
      Icon: <BookmarkRemoveIcon />,
      strParam: "",
      showMenu: "true",
    },
    {
      strScreenName: "Change passsword",
      strPath: "/ChangePassword",
      Icon: <></>,
      strParam: "",
      showMenu: "false",
    },
    {
      strScreenName: "Initiate Call Off",
      strPath: "/InitiateCallOff",
      Icon: <HighlightOffIcon />,
      strParam: "",
      showMenu: "true",
    },
    {
      strScreenName: "Messages Template",
      strPath: "/MessagesTemplate",
      Icon: <MessageTwoTone />,
      strParam: "",
      showMenu: "true",
    },
  ],
  Plant: [
    {
      strScreenName: "Allocated commodities",
      strPath: "/AllocatedCommodities",
      Icon: <BookmarkAddedTwoToneIcon />,
      strParam: "",
      showMenu: "true",
    },
    {
      strScreenName: "Account Settings",
      strPath: "/Profile",
      Icon: <></>,
      strParam: "",
      showMenu: "false",
    },
    {
      strScreenName: "Change passsword",
      strPath: "/ChangePassword",
      Icon: <></>,
      strParam: "",
      showMenu: "false",
    },
  ],
  Client: [
    {
      strScreenName: "Book Commodities",
      strPath: "/BookCommodities",
      Icon: <BookmarkAddTwoToneIcon />,
      strParam: "",
      showMenu: "true",
    },
    {
      strScreenName: "Allocated commodities",
      strPath: "/AllocatedCommodities",
      Icon: <BookmarkAddedTwoToneIcon />,
      strParam: "",
      showMenu: "true",
    },
    {
      strScreenName: "Account Settings",
      strPath: "/Profile",
      Icon: <></>,
      strParam: "",
      showMenu: "false",
    },
    {
      strScreenName: "Change passsword",
      strPath: "/ChangePassword",
      Icon: <></>,
      strParam: "",
      showMenu: "false",
    },
  ],
};

export const MainRoutes = (role) => {
  const routes = views[role].map((view) => {
    let ViewElement = Loadable(lazy(() => import(`../Views${view.strPath}`)));
    return { path: view.strPath + view.strParam, element: <ViewElement /> };
  });

  return [
    {
      path: "/",
      element: <MainLayout views={views[role]} />,
      children: [
        {
          path: "/",
          element: <></>,
        },
        ...routes,
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ];
};
