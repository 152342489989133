import { Outlet } from "react-router-dom";
import { Card, styled, Box } from "@mui/material";

const LayoutContainer = styled(Box)(({ theme }) => ({
    height: "100vh",
    display: "flex",
}))

const MainContent = styled(Box)(({ theme }) => ({
    width: "90%",
    [theme.breakpoints.up("md")]: {
        justifyContent: "start",
        width: "50%",
    },
    [theme.breakpoints.down("md")]: {
        margin: "0 auto"

    }
}))

const StyledPaper = styled(Card)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%"
}))

const ImageContainer = styled(Box)(({ theme }) => ({
    height: "100%",
    width: "80%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
        display: "none"
    }
}))

const AuthLayout = () => {
    return (
        <LayoutContainer>
            <MainContent>
                <StyledPaper elevation={0}>
                    <Outlet />
                </StyledPaper>
            </MainContent>
            <ImageContainer>
                <img src="img/landing.svg" width="100%" />
            </ImageContainer>
        </LayoutContainer >
    );
};

export default AuthLayout;