import { Box, Button, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import RHFTextField from "../../../Components/RHFControls/RHFTextField";
import PhoneNumberInput from "Components/PhoneNumberInput";
import RHFRadioGroup from "Components/RHFControls/RHFRadioGroup";

export const SignUpForm = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    setError,
    register,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit((data) => onSubmit(data, setError))}
        noValidate
      >
        <RHFTextField
          name="strUserFirstName"
          control={control}
          label={"First Name"}
          required={true}
        />
        <RHFTextField
          name="strUserLastName"
          control={control}
          label={"Last Name"}
          required={true}
        />
        <RHFTextField
          name="strUserCompanyName"
          control={control}
          label={"Company Name"}
          required={true}
        />
        <PhoneNumberInput
          control={control}
          errors={errors}
          setValue={setValue}
          trigger={trigger}
        />
        <RHFTextField
          name="strUserEmail"
          control={control}
          label={"Email"}
          required={true}
          type="email"
        />
        <RHFRadioGroup
          name="strContactBy"
          label="Prefered to contact by"
          control={control}
          defaultValue={"BySms"}
          required
          options={[
            { value: "BySms", label: "SMS" },
            { value: "ByEmail", label: "Email" },
          ]}
        />
        <RHFTextField
          name="strUserPassword"
          control={control}
          label={"Password"}
          required={true}
          type="password"
        />
        <RHFTextField
          name="strUserConfirmPassword"
          control={control}
          label={"Confirm Password"}
          required={true}
          type="password"
        />
        <Button type="submit" sx={{ width: "100%" }}>
          {"Sign up"}
        </Button>
      </Box>
    </>
  );
};
