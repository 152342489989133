import { CastData } from "Common/Utils";
import axios from "axios";


export const getAll = async () => {
    const response = await axios.get(`api/User/GetAll`);
    return response;
  };

  export const getUserByPk = async (userId) => {
    const response = await axios.get(`api/User/GetByPk/${userId}`);
    return response;
  }

  export const addUser = (data) => async (onSuccess, onFail) => {
    const response = await axios.post("api/User/AddUser", data);
    if (response.statusCode === 200) {
      onSuccess(response.message);
    } else {
      onFail(response.message);
    }
  };
  
  export const updateUser = (data) => async (onSuccess, onFail) => {
    const response = await axios.post("api/User/UpdateUser", CastData(data));
    if (response.statusCode === 200) {
      onSuccess(response.message);
    } else {
      onFail(response.message);
    }
  };
  
  export const deleteUser = (data) => async (onSuccess, onFail) => {
    const response = await axios.post(`api/User/DeleteUser`, data);
    if (response.statusCode === 200) {
      onSuccess(response);
    } else {
      onFail(response.message);
    }
  };

  export const getAllCommodities = async () => {
    const response = await axios.get(`api/Commodity/GetAll`);
    return response;
  };

  export const getAllUserCommodities = async (userId) => {
    const response = await axios.get(`api/User/GetAllCommoditiesByUserId/${userId}`);
    return response;
  };
