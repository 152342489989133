import { Card, CardContent, Box } from "@mui/material";
import { UtilContext } from "../../../Contexts/UtilContext";
import { useContext } from "react";
import { SignUpForm } from "./forms";
import { SignUpUser } from "./service";
import { useNavigate } from 'react-router-dom';

const SignUp = (props) => {
    const { ShowSuccess, ShowError, ShowLoader } = useContext(UtilContext);
    const navigate = useNavigate();

    const handleSingUpSuccess = (res) => {
        ShowSuccess(res.message)
        navigate('/Login');
    }

    const handleSubmit = async (data, setError) => {
        // Perform additional validation for password match
        if (data.strUserPassword !== data.strUserConfirmPassword) {
            setError("strUserConfirmPassword", {
                type: "manual",
                message: "Passwords do not match",
            });
            return;
        }
        if (!/^\S+@\S+\.\S+$/.test(data.strUserEmail)) {
            setError("strUserEmail", {
                type: "manual",
                message: "Invalid email address",
            });
            return;
        }
        ShowLoader(true);
        await SignUpUser(data)(handleSingUpSuccess, ShowError);
        ShowLoader(false);
    };

    return (
        <>
            <Card elevation={0} square sx={{ width: "70%", p: 2 }}>
                <CardContent>
                    <Box component="img" src="/img/logo.png"
                        alt="avatar"
                        sx={{
                            width: "150px",
                            height: "150px",
                            display: "block",
                            margin: "0 auto"
                        }} />
                    <Box sx={{ p: 5, pb: 3 }}>
                        <SignUpForm onSubmit={handleSubmit}/>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default SignUp;