import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@mui/material"
import { Controller } from "react-hook-form"

const RHFRadioGroup = (props) => {
    const onKeyDown = e => {
        if (props.disabled) {
            e.preventDefault()
        }
    }

    const handleOnChange = (event, field) => {
        if (!props.disabled) {
            props.onChange(event)
            field.onChange(event)
        }
    }

    return (
        <Controller defaultValue={props.defaultValue} name={props.name} control={props.control} rules={{
            required: { value: props.required, message: "Field is required" }
        }} render={({ field, fieldState: { error } }) => (
            <FormControl sx={{ m: 0, ...props.sx }} disabled={props.disabled} error={!!error} required={props.required}>
                <FormLabel>{props.label}</FormLabel>
                <RadioGroup name={props.name} row={props.row} {...field} onChange={(e) => handleOnChange(e, field)}>
                    {props.options.map(item => <FormControlLabel key={item.value} onKeyDown={onKeyDown} control={<Radio />} value={item.value} label={item.label} />)}
                </RadioGroup>
                {error && <FormHelperText sx={{ margin: 0 }}>{error.message}</FormHelperText>}
            </FormControl >
        )} />
    )
}

RHFRadioGroup.defaultProps = {
    onChange: () => { },
    defaultValue: "",
    sx: {},
    row: true
}

export default RHFRadioGroup