const { TextField } = require("@mui/material");
const { Controller } = require("react-hook-form");

const RHFTextField = (props) => {
    const handleChange = (e, RHFSetter) => {
        if (!props.disabled) {
            if (!["number", "integer"].includes(props.type)) {
                RHFSetter(e)
            }
            else if (props.type === "number" && RegExp("^[0-9.]*$").test(e.target.value)) {
                RHFSetter(e)
            }
            else if (props.type === "integer" && RegExp("^[0-9]*$").test(e.target.value)) {
                RHFSetter(e)
            }
        }
    }

    const validateValue = value => {
        if (props.required && String(value ?? "").trim() === "") {
            return "Field is required"
        }
        if (props.rules?.hasOwnProperty("validate")) {
            return props.rules.validate(value)
        }
        return true
    }

    const isHidden = props.hide ? true : false; // Check if hide prop is provided

    return (
        <Controller
            name={props.name}
            control={props.control}
            defaultValue={props.defaultValue}
            rules={{
                ...props.rules,
                validate: validateValue

            }}
            render={({ field, fieldState: { error } }) => (
                isHidden ? null : ( // Render null if hide prop is true
                <TextField
                    fullWidth {...field}
                    onChange={(e) => handleChange(e, field.onChange)}
                    value={field.value ?? props.defaultValue}
                    InputProps={props.InputProps}
                    color={props.color}
                    multiline={props.multiline}
                    required={props.required}
                    label={props.label}
                    type={["integer", "number"].includes(props.type) ? "text" : props.type}
                    error={!!error}
                    helperText={error?.message}
                    disabled={props.disabled}
                    sx={props.sx}
                    minRows={props.minRows}
                />)
            )}
        />
    );
};

export default RHFTextField;

RHFTextField.defaultProps = {
    defaultValue: "",
    sx: {},
    minRows: 1,
    type: "text",
    multiline: false,
};