import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AccountCircle, KeyTwoTone, Logout } from "@mui/icons-material";
import { ThemeContext } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { getUserByPk } from "Views/Users/service";
import { CastEnum } from "Common/Utils";
import { UserRoleEnum } from "Common/UserRoleEnum";
import { UtilContext } from "Contexts/UtilContext";
import jwt_Decode from "jwt-decode";

const UserProfileMenu = () => {
  const [open, setOpen] = useState(false);
  const [userInfo, setUser] = useState(null);
  const { ShowLoader, ShowSuccess, ShowError } = useContext(UtilContext);
  const anchorRef = useRef(null);
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      ShowLoader(true);
      let user = await getUserByPk(
        jwt_Decode(localStorage.getItem("user"))?.intUserId
      );
      setUser(user);
      ShowLoader(false);
    };
    fetchData();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("refreshToken");
    navigate("/login");
  };

  const handleAccountSettings = () => {
    setOpen(false);
    navigate("/Profile");
  };

  const handleChangePassword = () => {
    navigate("/ChangePassword");
  };

  return (
    <>
      <Chip
        ref={anchorRef}
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.light,
            background: `${theme.palette.primary.light}!important`,
            color: theme.palette.primary.main,
            "& svg": {
              stroke: theme.palette.primary.main,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            sx={{
              width: "32px",
              height: "32px",
              margin: "8px 0 8px 8px",
              cursor: "pointer",
            }}
            src="/assets/images/profile.jpg"
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label=""
        variant="outlined"
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        sx={{ zIndex: 9999, margin: "6px !important" }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <Paper elevation={16} sx={{ boxShadow: 16 }}>
              <Box sx={{ p: 2 }}>
                <Stack spacing={0.5}>
                  <Typography>
                    <strong>{userInfo?.strUserFirstName}</strong>{" "}
                    <strong>{userInfo?.strUserLastName}</strong>
                  </Typography>
                </Stack>
                <Typography variant="subtitle2">
                  {CastEnum(UserRoleEnum, userInfo?.intRoleId)}
                </Typography>
              </Box>
              <Divider />
              <PerfectScrollbar
                style={{
                  height: "100%",
                  maxHeight: "calc(100vh - 250px)",
                  overflowX: "hidden",
                }}
              >
                <Box sx={{ p: 2 }}>
                  <List
                    component="nav"
                    sx={{
                      width: "100%",
                      maxWidth: 350,
                      minWidth: 300,
                      borderRadius: "10px",
                      backgroundColor: theme.palette.background.paper,
                      "& .MuiListItemButton-root": {
                        mt: 0.5,
                      },
                    }}
                  >
                    <ListItemButton onClick={handleAccountSettings}>
                      <ListItemIcon>
                        <AccountCircle />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">
                            Account Settings
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton onClick={handleChangePassword}>
                      <ListItemIcon>
                        <KeyTwoTone />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">
                            Change password
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton onClick={handleLogout}>
                      <ListItemIcon>
                        <Logout />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2">Logout</Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </Box>
              </PerfectScrollbar>
            </Paper>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export default UserProfileMenu;
