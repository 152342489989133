import { Card, CardContent, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Landing = () => {
    const navigate = useNavigate();

    return (
        <>
            <Card elevation={0} square sx={{ width: "70%", p: 2 }}>
                <CardContent>
                    <Box component="img" src="/img/logo.png"
                        alt="avatar"
                        sx={{
                            width: "150px",
                            height: "150px",
                            display: "block",
                            margin: "0 auto"
                        }} />
                    <Box sx={{ p: 5, pb: 3 }}>
                        <Button onClick={() => navigate('/Login')} sx={{ width: '100%' }}>
                            {"Login"}
                        </Button><br /><br />
                        <Button onClick={() => navigate('/SignUp')} sx={{ width: '100%' }}>
                            {"Sign Up"}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default Landing;