import React, {useEffect} from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import './sty.css'
const PhoneNumberInput = ({ control, errors, setValue, phoneNumber="1", trigger }) => {
    useEffect(() => {
        // Set the initial value for the phone number field
        setValue('strUserPhoneNumber', phoneNumber);
    
        // Manually trigger the onChange event
        const event = {
          target: {
            name: 'strUserPhoneNumber',
            value: phoneNumber,
          },
        };
        trigger('strUserPhoneNumber', event);
      }, [setValue, control]);
      const handlePhoneChange = (value) => {
        setValue('strUserPhoneNumber', value); // Update the phone number value
        trigger('strUserPhoneNumber'); // Trigger validation
      };
  return (
    <Controller
      name="strUserPhoneNumber"
      control={control}
      rules={{ required: 'Phone number is required' }}
      render={({ field }) => (
        <div style={{ marginTop: '15px', marginBottom: '10px' }}>
          <PhoneInput
            id="PhoneNumber"
            specialLabel={'Phone Number'}
            value={field.value}
            inputStyle={{
              width: '100%',
              border: errors.strUserPhoneNumber ? '1px solid #d32f2f' : '1px solid #ccc',
              borderRadius: '4px',
            }}
            onChange={(value) => handlePhoneChange(value)}
          />
          {errors.strUserPhoneNumber && (
            <p style={{ color: '#d32f2f', marginTop: '5px', fontSize: '0.75rem' }}>
              {errors.strUserPhoneNumber.message}
            </p>
          )}
        </div>
      )}
    />
  );
};

export default PhoneNumberInput;
