import { CssBaseline } from '@mui/material';
import ErrorBoundary from './ErrorBoundary';
import NavigationScroll from './Layout/NavigationScroll';
import { MainRoutes } from 'Routes/MainRoutes';
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { AuthRoutes } from 'Routes/AuthRoutes';

const App = () => {
  const [activeRoutes, setRoutes] = useState([]);
  const user = localStorage.getItem("user");
  useEffect(() => {
    if (Boolean(user)) {
      const decodedUser = jwt_decode(user);
      if (decodedUser) {
        const mainRoutes = MainRoutes(decodedUser.strRoleName);
        setRoutes(mainRoutes);
      }
    } else {
      setRoutes(AuthRoutes);
    }
  }, [user]);

  const routes = useRoutes(activeRoutes);
  return (
    <NavigationScroll>
      <CssBaseline />
      <ErrorBoundary>{routes}</ErrorBoundary>
    </NavigationScroll>
  );
};

export default App;