import { Card, Typography, CardContent, useTheme, Box } from "@mui/material";
import { UtilContext } from "../../../Contexts/UtilContext";
import { useContext } from "react";
import { LoginForm } from "./forms";
import { LoginUser } from "./service";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const { ShowError, ShowLoader } = useContext(UtilContext);
    const navigate = useNavigate();

    const handleLoginSuccess = ({ token, refreshToken }) => {
        localStorage.setItem("user", token);
        localStorage.setItem("refreshToken", refreshToken);
        navigate('/')
    };

    const handleSubmit = async (data) => {
        ShowLoader(true);
        await LoginUser(data)(handleLoginSuccess, ShowError);
        ShowLoader(false);
    };

    return (
        <>
            <Card elevation={0} square sx={{ width: "70%", p: 2 }}>
                <CardContent>
                    <Box component="img" src="/img/logo.png"
                        alt="avatar"
                        sx={{
                            width: "150px",
                            height: "150px",
                            display: "block",
                            margin: "0 auto"
                        }} />
                    <Box sx={{ p: 5, pb: 3 }}>
                        <LoginForm onSubmit={handleSubmit} />
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default Login;