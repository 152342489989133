import axios from "axios";

let isRefreshing = false;

//axios.defaults.baseURL = process.env.REACT_APP_SERVICE_BASE_URL;
axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("user");
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
axios.interceptors.response.use(
    (response) => {
        return response.data
    },
    async (error) => {
        const originalRequest = error.config;
        console.log(error)
        if (error?.response?.status === 401) {
            if (!isRefreshing) {
                isRefreshing = true;
            console.log('testtesttest')
            originalRequest._retry = true;
            try {
                const refreshToken = localStorage.getItem("refreshToken");
                const response = await axios.get('api/Authorization/RefreshToken', { params: { refreshToken: refreshToken } });
                console.log(response)
                const newAccessToken = response.data.token;
                localStorage.setItem("user", newAccessToken);
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                console.log(originalRequest)
                return api(originalRequest);
              } catch (refreshError) {
               
                console.error('Refresh token failed:', refreshError);
               
              }
            }
            //window.location.reload();
            return Promise.reject(error);
        }
        if (error?.response?.status === 403) {
            return Promise.resolve({ statusCode: 403, message: "You're UnAuthorized for this action" });
        }
        if (error?.code === "ERR_NETWORK") {
            console.error(error);
            return Promise.resolve({ statusCode: 0, mssage: "A network connection error has occurred" });
        }
        console.error(error);
        return Promise.resolve({ statusCode: 0, message: "" });
    }
);

function api(requestConfig) {
    return axios(requestConfig);
}