import axios from "axios"

export const SignUpUser = (data) => async (onSuccess, onFail) => {
    const response = await axios.post("api/Authorization/SignUp", data)
    if (response.statusCode === 200) {
        onSuccess(response)
    }
    else {
        onFail(response.message)
    }
}
