import { createTheme } from "@mui/material"
import { components } from "./components";
import { palette } from "./palette";

const themeVariables = {
    appBarHeight: "75px",
    drawerWidth: 280
}

export const initTheme = ({ mode, drawerOpen }) => {
    const baseTheme = createTheme({
        variables: themeVariables,
        palette: palette(mode)
    })

    return createTheme(baseTheme, {
        components: components(baseTheme, drawerOpen),
    })
}
