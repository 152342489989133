import { createContext, useState } from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { initTheme } from "./Theme/index";

export const ThemeContext = createContext();
const ThemeProvider = ({ children }) => {
    const [mode, setMode] = useState("light")
    const [drawerOpen, setDrawerOpen] = useState(true)

    return (
        <ThemeContext.Provider value={{ setDrawerOpen, drawerOpen }}>
            <MuiThemeProvider theme={initTheme({ mode, drawerOpen })}>
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;